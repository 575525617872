const env = window.TeamSnap.environment;
const dataLayer = window.dataLayer;
window.trackerDebug = {};

const gtmTracker = (obj) => {
  if (env === 'production' && dataLayer) {
    dataLayer.push({
      'event': obj.event,
      'eventCategory': obj.eventCategory,
      'eventAction': obj.eventAction,
      'eventLabel': obj.eventLabel
    });
  } else {
    window.trackerDebug = obj;
  }
};

export {
  gtmTracker
};
