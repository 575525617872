import React, { Component } from 'react';
import MyInvoicesInfo from '../MyInvoicesInfo';
import NoInvoices from '../NoInvoices';
import ErrorMessage from '../ErrorMessage';
import Loader from '../Loader';
import {
  onError,
  onSuccess,
  request
} from '../../utils/request';
import { sortBy } from '../../utils/utils';
import { convertFromCollection } from '../../utils/collectionJSON';
import {
  INVOICES_ERROR_MESSAGE,
  INVOICES_TITLE
} from './constants';

class MyInvoices extends Component {
  constructor (props) {
    super(props);
    this.state = {
      errorMessage: '',
      invoices: [],
      invoicesLoaded: false,
      userId: props.userId
    };
  }

  componentDidMount () {
    this.requestInvoices();
  }


  requestInvoices = () => {
    request.get(`/invoices/search?user_id=${this.state.userId}`).then((response) => {
      if (onSuccess(response)) {
        const collection = convertFromCollection(response.data);
        const openInvoices = collection.items.filter(item => item.status === 'open');
        this.setState({ invoices: sortBy(openInvoices, 'dueAt') }, () => {
          this.setState({ invoicesLoaded: true });
        });
      } else if (onError(response)) {
        this.setState({ errorMessage: { INVOICES_ERROR_MESSAGE } });
      }
    });
  }

  renderMyInvoicesInfo = () => {
    const { errorMessage, invoices } = this.state;

    if (!errorMessage.length) {
      if (invoices.length) {
        return (
          invoices.map(invoice => (
              <MyInvoicesInfo
                balance={ invoice.balanceWithCurrency }
                dueDate={ invoice.dueAt }
                invoiceFrom={ invoice.invoiceFrom }
                key={ invoice.id }
                paymentUrl={ invoice.paymentUrl }
                recipient={ invoice.invoiceTo }
                title={ invoice.title }
              />
            ))
        );
      } else {
        return (
          <NoInvoices />
        );
      }
    } else {
      return (
        <ErrorMessage
          errorMessage={ errorMessage }
        />
      );
    }
  }

  render = () => {
    const { invoicesLoaded } = this.state;

    if (invoicesLoaded) {
      return (
        <div className='Panel'>
          <div className='Panel-header'>
            <h3 className='Panel-title'>{ INVOICES_TITLE }</h3>
          </div>
          <div className='Panel-body'>
            <div className='Panel-row'>
              { this.renderMyInvoicesInfo() }
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <Loader
          loadingMessage='Loading invoices'
        />
      );
    }
  }
}

export default MyInvoices;
