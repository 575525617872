import React from 'react';
import PropTypes from 'prop-types';

const displayLoadingMessage = (loadingMessage) => {
  if (loadingMessage.length) {
    return (
      <p>{ loadingMessage }</p>
    );
  } else {
    return (
      <p>Loading</p>
    );
  }
};

const Loader = ({ loadingMessage }) => (
    <div>
      { displayLoadingMessage(loadingMessage || '') }
    </div>
  );

Loader.propTypes = {
  loadingMessage: PropTypes.string
};

export default Loader;
