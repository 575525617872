import { camelize } from './utils';

const objectKeysToCamel = (key, value) => ({
  [camelize(key)]: value
});

const assignCollection = (collection, key, value) =>
  Object.assign(
    ...collection.map(data => (
      objectKeysToCamel(data[key], data[value])
    )
  ));

const collectionItem = item => ({
  href: item.href,
  rel: item.rel,
  links: assignCollection(item.links, 'rel', 'href'),
  ...assignCollection(item.data, 'name', 'value')
});

const convertFromCollection = data => ({
  ...data.collection,
  links: assignCollection(data.collection.links, 'rel', 'href'),
  items: data.collection.items ? data.collection.items.map(item => collectionItem(item)) : []
});


export {
  convertFromCollection
};
