import * as React from 'react';
import { CookiesProvider } from 'react-cookie';

import HotelPlanner from '../HotelPlanner';

export default () => (
  <CookiesProvider>
    <HotelPlanner />
  </CookiesProvider>
);
