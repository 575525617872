import React from 'react';
import ReactDOM from 'react-dom';
import { datadogRum } from '@datadog/browser-rum';
// Import components here
import HotelPlannerApp from './components/HotelPlannerApp';
import MyInvoicesContainer from './components/MyInvoicesContainer';

datadogRum.init({
  applicationId: 'c82e548a-d4a3-40ac-a83e-3c40a6326a16',
  clientToken: 'pub3b46a7482507488c65881b8cdfd8c18d',
  site: 'datadoghq.com',
  service: 'js-dashboard',
  env: process.env.NODE_ENV || 'development',
  sampleRate: 10,
  trackInteractions: false
});

window.TeamSnap = window.TeamSnap || {};
window.TeamSnap.React = React;
window.TeamSnap.ReactDOM = ReactDOM;

// Add components here
window.TeamSnap.Components = {
  HotelPlannerApp,
  MyInvoicesContainer,
};

/*
Classic should look something like this
<script type="text/javascript" src="http://localhost:7654/standalone-components.js"></script>
<script type="text/javascript">
  var rootElement = window.TeamSnap.React.createElement(window.TeamSnap.Components.SomeComponent)
  window.TeamSnap.ReactDOM.render(rootElement, document.getElementById('react-app'))
</script>
*/
