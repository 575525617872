import AXIOS from 'axios';

const request = AXIOS.create({
  baseURL: window.TeamSnap.apiRoot,
  headers: {
    accept: 'application/vnd.collection+json',
    authorization: `Bearer ${window.TeamSnap.authenticationToken}`,
  },
});

const onSuccess = (response: { data?: {}; status: number }) => response.status === 200;

const onError = (response: { error?: string; status: number }) => 
  // Add HB stuff here
   response.status !== 200;


export { onError, onSuccess, request };
