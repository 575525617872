import React from 'react';

const NoInvoices = () => (
    <div>
      <h4 className='u-spaceBottomSm'>No invoices currently due.</h4>
      <p>Thank you for being awesome!</p>
    </div>
  );

export default NoInvoices;
