import * as React from 'react';

import DismissIcon from '../DismissIcon';
import ErrorMessage from '../ErrorMessage';

import { onSuccess, onError, request } from '../../utils/request';
import { convertFromCollection } from '../../utils/collectionJSON';

import hotelPlannerLogo from './hotel-planner-card-image.png';
import { ReactCookieProps } from 'react-cookie';

interface AxiosResponse {
  data?: {}
  error?: string
  status: number
}

interface Collection {
  items: [
    {
      canDisplayAds: boolean
      id: number
    }
  ]
}

interface State {
  canDisplayAds: boolean
  dismissedCard?: boolean
  errorMessage?: string
}

class HotelPlanner extends React.Component<ReactCookieProps, State> {
  state = {
    canDisplayAds: false,
    dismissedCard: false,
    errorMessage: '',
  }

  componentDidMount() {
    const { cookies } = this.props;
    if (cookies && cookies.get('dismissedHotelPlanner') !== 'true') {
      this.sendRequest();
    }
  }

  sendRequest = (): void => {
    // TODO use case for Context API?
    // Making request to `/me` in <MyInvoicesContainer />`
    request.get('/me').then((response: AxiosResponse) => {
      if (onSuccess(response)) {
        const collection: Collection = convertFromCollection(response.data);
        const userId: number = collection.items[0].id;

        request
          .get(`/advertisements/search?user_id=${userId}`)
          .then((response: AxiosResponse) => {
            if (onSuccess(response)) {
              const collection: Collection = convertFromCollection(
                response.data
              );
              const canDisplayAds: boolean = collection.items[0].canDisplayAds;
              this.setState({ canDisplayAds });
            } else if (onError(response)) {
              this.setState({
                errorMessage: response.error,
              });
            }
          });
      } else if (onError(response)) {
        this.setState({
          errorMessage: response.error,
        });
      }
    });
  }

  handleDismiss = (): void => {
    const { cookies } = this.props;
    cookies?.set('dismissedHotelPlanner', true);

    this.setState({
      dismissedCard: true,
    });
  }

  render() {
    const { cookies } = this.props;
    const { canDisplayAds, dismissedCard, errorMessage } = this.state;

    // Do not return anything if either of the following is true:
    // 1. Cookies are set from clicking the dismiss button
    // 2. Ads are not shown for this user - SNAPI's `canDisplayAds` lazy attribute
    if (cookies && cookies.get('dismissedHotelPlanner') === 'true') {
      return null;
    } else if (errorMessage.length) {
      return <ErrorMessage errorMessage={ errorMessage } />;
    } else if (!dismissedCard || canDisplayAds) {
      return (
        <div className="Panel">
          <div className="Panel-header">
            <h4 className="Panel-title">Save on Travel</h4>
            <button
              className="Button Button--smallSquare u-borderNone u-colorPrimary"
              onClick={ this.handleDismiss }
            >
              <DismissIcon />
            </button>
          </div>
          <div className="Panel-body u-padMd">
            <img src={ hotelPlannerLogo } alt="HotelPlanner logo" />
            <p className="u-spaceBottomMd u-spaceTopMd">
              TeamSnap has joined up with HotelPlanner to provide exclusive
              hotel discounts to TeamSnap users. Save on your next away game,
              get group discounts and save on personal travel too!
            </p>
            <div className="u-flex u-flexJustifyCenter u-textCenter">
              <a
                className="Button Button--primary u-sizeFull"
                href="https://teamsnap.hotelplanner.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Browse Travel Deals
              </a>
            </div>
          </div>
        </div>
      );
    } else return null;
  }
}

export default HotelPlanner;
