import React, { Component } from 'react';
import MyInvoices from '../MyInvoices';
import ErrorMessage from '../ErrorMessage';
import { onSuccess, onError, request } from '../../utils/request';
import { convertFromCollection } from '../../utils/collectionJSON';

class MyInvoicesContainer extends Component {
  constructor (props) {
    super(props);
    this.state = {
      errorMessage: '',
      me: {}
    };
  }

  componentDidMount () {
    request.get('/me').then((response) => {
      if (onSuccess(response)) {
        const collection = convertFromCollection(response.data);
        this.setState({ me: collection.items[0] });
      } else if (onError(response)) {
        this.setState({ errorMessage: response.error });
      }
    });
  }


  render = () => {
    const { errorMessage, me } = this.state;

    if (me.id) {
      return (
        <MyInvoices
          userId={ me.id }
        />
      );
    } else if (errorMessage.length) {
      return (
        <ErrorMessage
          errorMessage={ errorMessage }
        />
      );
    } else {
      return null;
    }
  }
}

export default MyInvoicesContainer;
