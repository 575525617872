import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '../../utils/date';
import { gtmTracker } from '../../tracker';

const trackClick = () => {
  const trackedObj = {
    event: 'OpenInvoice',
    eventCategory: 'My Invoices',
    eventAction: 'Open Invoice',
    eventlabel: 'Payments Due',
  };

  gtmTracker(trackedObj);
};

const MyInvoicesInfo = ({
  balance,
  dueDate,
  invoiceFrom,
  paymentUrl,
  recipient,
  title,
}) => (
    <div>
      <div className="u-spaceBottomMd">
        <div className="Card">
          <div className="Card-header">
            <h4 className="Card-headerTitle">{ invoiceFrom }</h4>
          </div>
          <div className="Card-body Grid">
            <div className="Grid-cell u-sizeFill">
              <strong className="MyInvoices-invoiceTitle">{ title }</strong>
              <span className="MyInvoices-invoiceRecipient">{ recipient }</span>
            </div>
            <div className="Grid-cell u-sizeFit u-textRight">
              <strong className="MyInvoices-invoiceBalance">{ balance }</strong>
            </div>
          </div>
          <div className="Grid u-flexAlignItemsCenter Card-footer">
            <div className="Grid-cell u-sizeFill">
              Due: { formatDate(dueDate) }
            </div>
            <div className="Grid-cell u-sizeFit u-textRight">
              <a
                href={ paymentUrl }
                target="_blank"
                rel="noopener noreferrer"
                onClick={ trackClick }
                className="button small success"
              >
                Pay Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

MyInvoicesInfo.propTypes = {
  balance: PropTypes.string.isRequired,
  dueDate: PropTypes.string.isRequired,
  invoiceFrom: PropTypes.string.isRequired,
  paymentUrl: PropTypes.string.isRequired,
  recipient: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default MyInvoicesInfo;
